<template>
  <v-container>
    <create-user type="update" :user="user" v-on:updateuser="userUpdated"></create-user>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CreateUser from "../components/users/CreateUser.vue";

export default {
  components: {
    CreateUser,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    userUpdated(data) {
      this.$store.dispatch("getUser", data).catch((err) => {
        console.log(err);
      });

      this.snackbarText = "Benutzerdaten wurden erfolgreich geändert";
      this.snackbar = true;
    },
  },
};
</script>
